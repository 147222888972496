import logo from './css/images/logo.png';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Routes, Route, Link, Navigate} from 'react-router-dom';
import './App.css';
import './css/header.css'
import Home from './home';
import AboutUs from './about';
import OurServices from './services';
import FAQs from './faq';
import Contact from './contact';
import BookNow from './BookNow';
import Register from './Login/Register';
import Dashboard from './components/Dashboard';
import Admin from './components/Admin';
import Preferences from './components/Preferences';
import ForgotPassword from './Login/ForgotPassword';
import ErrorPage from './Error.js';
import Test from './components/Test';
import { useEffect, useState } from 'react';
import Menu from './components/Menu';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';

window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
    
    document.getElementById("navbar").style.backgroundColor = "white";

    if (localStorage.getItem('page') === 'home') {
      document.querySelector('.menu').style.marginTop = "12vh";
    }
  } else {
    
    document.getElementById("navbar").style.backgroundColor = "transparent";
    document.querySelector('.menu').style.marginTop = "12vh";
  }
}


export default function Header() {

  var [userPage, setUserPage] = useState("");
  const [isAuth, setIsAuth] = useState('');
  const [burger, setBurger] = useState(false);

  useEffect(() => {


    setIsAuth(localStorage.getItem("auth"));
    if (isAuth) {
      setUserPage("Dashboard");
    } else {
      setUserPage("Book Now");
    }

  }, [isAuth]);

  const displayMenu = () => {

    const menu = document.querySelector('.menu');
    const body = document.querySelector('.main');
    const footer = document.querySelector('.footer');
    const menuBtn = document.querySelector('.menu-btn');
    if (menu.style.display === "none") {
      setBurger(true);  
      menu.style.display = "flex";
      menu.style.position = "fixed";
      body.style.opacity = "0.1";
      footer.style.opacity = "0.1";
      document.body.style.overflow = "hidden";
    } else {
      setBurger(false);
      menu.style.display = "none";
      body.style.opacity = "1";
      footer.style.opacity = "1";
      document.body.style.overflow = "auto";
    }
  
  }
  
const fixBurger = () => {
  setBurger(false);
}

  
  return (
  <div>
    
    <Router>
      <div className="Header">
        <nav id='navbar'>
          <div className={"menu-btn " + (burger ? "open":"") } onClick={displayMenu} >
                <div className="menu-btn__burger"></div>
            </div>
          <div className='left-menu'>
            <Link to="/">Home</Link>
            <Link to="/about">About Us</Link>
            <Link to="/services">Our Services</Link>
            <Link to="/faqs">FAQs</Link>
            <Link to="/contact">Contact Us</Link>
          </div>
          <div className='middle-menu'>
            <Link to="/" onClick={fixBurger}><img src="https://memorialmaids.nyc3.digitaloceanspaces.com/images/logo.png" alt="Logo" /></Link>
          </div>
          <div className='right-menu'>

            <Link to="/BookNow" className='bookButton'>{userPage}</Link>

          </div>
          <div className="schedule">
          <Link to="/BookNow"> <i class="bi bi-calendar-plus-fill"></i></Link>
          </div>  
        </nav>
        <Menu fixBurger={fixBurger} setBurger={setBurger} />
      </div>
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="/about" element={ <AboutUs/> } />
        <Route path="/contact" element={ <Contact/> } />
        <Route path="/services" element={ <OurServices/> } />
        <Route path="/FAQs" element={ <FAQs/> } />
        <Route path="/BookNow" element={ <Test /> } />
        <Route path="/Dashboard" element={ <Dashboard /> } />
        <Route path="/Preferences" element={ <Preferences/> } />
        <Route path="/register" element={ <Register/> } />
        <Route path="/termsofservice" element={ <TermsOfService /> } />
        <Route path="/privacypolicy" element={ <PrivacyPolicy />} />
        <Route path="/forgotpassword" element={ <ForgotPassword/> } />
        <Route path="/resetpassword" element={ <resetPassword/> } />
        <Route path="*" element={ <ErrorPage/> } />
      </Routes>
    </Router>  
</div>
);

  }
