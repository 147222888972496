
export default function fixNav() {
    const body = document.querySelector('.main');
    const footer = document.querySelector('.footer');
    const menu = document.querySelector('.menu');
    menu.style.display = "none";
    document.body.style.overflow = "auto";
    footer.style.opacity = "1";
    body.style.opacity = "1";
    
}