import React, {useEffect} from 'react'
import { Link } from "react-router-dom";
import './css/faq.css';
import Menu from './components/Menu';
import fixNav from './FixNav';

function FAQs() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        localStorage.setItem('page', 'faqs');
        fixNav();
      }, []);
    
    return (
    <div>
        <div className='main'>
            <div className='faqs'>
                <div className='faq-title'>
                    <h1>FAQs</h1>
                </div>
                <div className='faq-container'>
                    <div className='faq'>
                        <h4>What does a cleaning consist for entire house?</h4>
                        <p>For all rooms, we will vacuum carpets, sweep and mop, pick up trash, sanitize high traffic areas, clean furniture, and dust ceiling fans and curtains.</p>
                    </div>
                    <div className='faq'>
                        <h4>What does a cleaning include for my kitchen?</h4>
                        <p>For the kitchen, we clean the outside of appliances, inside microwave, scrub and sanitize sinks and counters.</p>
                    </div>
                    <div className='faq'>
                        <h4>What does a cleaning include for my bedrooms?</h4>
                        <p>For the bedrooms, we change or make the bed if sheets are provided. All other services like sweeping, mopping, and dusting are included. </p>
                    </div>
                    <div className='faq'>
                        <h4>What does a cleaning include for my bathrooms?</h4>    
                        <p>We will scrub and disinfect the following: showers, sinks, tubs, toilets, and counters. Any mirrors will be cleaned. Lastly, we will polish fixtures.</p>
                    </div>
                    <div className='faq'>
                        <h4>What does a deep cleaning include?</h4>
                        <p>Our deep clean is focused on the kitchen, interior windows, bathroom, and other hard to reach areas. For the kitchen, we deep clean counters, backsplash, stove tops, face of appliances, table tops, and inside of select appliances (refrigerator, stove, and microwave). For the bathrooms, we scrub the vanity, bath, shower, and glass. Air vents are dusted and any cobwebs removed. For the rest of the home, we follow a standard cleaning procedure. </p>    
                    </div>
                    <div className='faq'>
                        <h4>What does a move in/out cleaning include?</h4>
                        <p>The moving process can be difficult, so let us provide you with a deep cleaned home. The move in/out cleaning consist of a deep cleaning (listed above), plus deep carpet cleaning. For the dishwasher and washer, we run a cleaning cycle with cleaning solution. Cabinets and closets are cleaned and disinfected. </p>
                    </div>
                    <div className='faq'>
                        <h4>What does a carpet cleaning include?</h4>
                        <p>To clean carpets it is recommended that the room is clear of furniture or other items, if not, our team can move furniture at an additional cost per room. After a room is cleared, carpet will be shampooed and dried. </p>
                    </div>
                    <div className='faq'>
                        <h4>What should I expect for my visit?</h4>
                        <p>Once you book a visit, we will contact you a day before to remind you of your appointment. Once we are on our way, we will send you a text message letting you know to expect us soon!</p>
                    </div>
                    <div className='faq'>
                        <h4>What if I'm not satisfied with the cleaning?</h4>
                        <p>Our Clean-Promise guarantees that you will be happy with our service or we will do it again. Please contact us immediately so we can schedule your visit.</p>
                    </div>
                    <div className='faq'>
                        <h4>Do I need to provide any supplies for my visit?</h4>
                        <p>No, we will have everything we need to clean your home. If you have a preferred product, we are more than happy to use your product instead.</p>
                    </div>
                    <div className='faq'>
                        <h4>What add-ons can I choose?</h4>
                        <p>We offer additional services to assist your cleaning needs! Grout and tile, interior windows, and other deep cleaning options are available at additional cost.</p>
                    </div>
                    <div className='faq'>
                        <h4>What if I'm late on my payment?</h4>
                        <p>We do provide a 2 day grace period before charging %2 on each day you are late.</p>
                    </div>
                    <div className='faq'>
                        <h4>What is the cancellation policy?</h4>
                        <p>Our cancellation policy is a 24-hour notice before the scheduled appointment. For cancellations within less than 24 hours, a 30% fee of the scheduled appointment will become due on the day of the cancelled service.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
  
  export default FAQs;
  
