

function SignUp () {
  
        return (
          <div>
            <div className='new-user'>
              <h2>Get a qoute and book today!</h2>
              <div className='user-form'>
                <div className="form">
                  <SignUp />
                </div>
              </div>
            </div>
          </div>
    );
    }

export default SignUp;
