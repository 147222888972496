import React from 'react'
import { Link } from "react-router-dom";
import './css/error.css';


function ErrorPage() {

  const styles = {
    phone: {
     
      fontFamily: 'Roboto, sans-serif',
      padding: '1em',
      
    },
    warning: {
      fontSize: '5em',
      color: 'red',
    }
   
  }

    return (
      <div>
        <div className='error'>
          <h1>PAGE NOT FOUND</h1>
          <i className="bi bi-exclamation-triangle-fill" style={styles.warning}></i>
          <p>Please contact us if you need help finding information or booking services.</p>
          <div className='links'>
            <Link to="/BookNow" className='bookButton' style={styles.phone}>Book Now</Link>
            <a className='bookButton' style={styles.phone} href="tel:1-2817134446"><i class="bi bi-phone"></i> Call Us</a>
            <a className='bookButton' style={styles.phone} href="mailto:contact@memorialmaids.com"><i class="bi bi-envelope"></i> Email</a>
          </div>
        </div>
      </div>
    );
  }
  
  export default ErrorPage;
  
