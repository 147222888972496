import React from 'react'
import { Link } from "react-router-dom";

function Preferences() {
    return (
    <div>
        <h1>Preferences</h1>

    </div>
    );
  }
  
  export default Preferences;
  
