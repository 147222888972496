import React, { useEffect, useState }  from 'react'
import { Link } from 'react-router-dom';
import "./css/contact.css";
import Menu from './components/Menu';
import fixNav from './FixNav';
import Axios from 'axios';

function Contact() {

    const [fullname, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        localStorage.setItem('page', 'contact');
        fixNav();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    }, []);
    

    const styles = {
        link: {
            color: 'black',
            fontFamily: 'Roboto, sans-serif',
            
        },
        button: {
            color: 'white',
        },
        
    }

    const submitContactForm = () => {

        Axios.post("http://localhost:3001/contact", {
            name: fullname,
            email: email,
            message: message,
        }).then((response) => {
            if (response.data.message) {
                alert(response.data.message);
            } else {
                alert('Thank you for contacting us. We will get back to you shortly.');
            }
        });

    }

    return (
        <div>
            <div className='main'>
                <div className='contact-us'>
                    <div className='contact-our-cleaning-service'>
                        <h1>Contact us.</h1>
                        <p>Our dedicated team of customer service experts are available during business hours. Visit our FAQs page for more information on our services. Get an estimate online, over the phone, or in-person. If you have any questions, please call or text Memorial Maids!</p>
                        <div className='contact'>
                            <div className='mailing-address'>
                                <p><b>Memorial Maids</b></p>
                                <p><b>PO Box 430871</b></p>
                                <p><b>Houston, TX 77243</b></p>
                            </div>
                            <div className='contact-snip'>
                                <p>
                                    <a style={styles.link} href='tel:+12817134446'>(281) 713-4446</a>
                                </p>                        
                                
                                <p>
                                    <a style={styles.link} href='mailto:contact@memorialmaids.com'> contact@memorialmaids.com</a>
                                </p>
                                
                                <p>
                                    <a target={"_blank"} href='https://www.facebook.com/memorial-maids'>
                                        <img src="https://memorialmaids.nyc3.digitaloceanspaces.com/images/facebook-icon.png" alt='facebook icon' />
                                    </a>
                                </p>
                            </div>
                            
                        </div>
                        
                        
                    </div>
                    <div className='contact-form'>
                        <form>
                            <label for='name'>Name</label>
                            <input type='text' id='name' name='name' placeholder='Name..' onChange={(e) => setName(e.target.value)}/>
                            <label for='email'>Email</label>
                            <input type='text' id='email' name='email' placeholder='Email..' onChange={(e) => setEmail(e.target.value)} />
                            <label for='subject'>Message</label>
                            <textarea id='message' name='message' placeholder='Write a message..' maxLength={500} onChange={(e) => setMessage(e.target.value)}></textarea>
                            <input type='submit' onClick={submitContactForm} value='Submit' />
                        </form>    

                    </div>
                    
                </div>
                <div className='filler'>
                    <div className='filler-inside'>
                        <h1>We deliver high-quality maid service every visit. From our family to yours! Thank you.</h1>
                        <p>
                            <Link to='/booknow' style={styles.button}>Book Now</Link>
                        </p>
                    </div>    
                </div>
            </div>
        </div>
    )
}

export default Contact;