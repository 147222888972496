import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Link } from "react-router-dom";
import "./css/home.css";
import { render } from 'react-dom';
import cleaningRagPromise from "./css/images/cleaning-icon-1.png";
import squeegee from "./css/images/cleaning-icon-2.png";
import window from "./css/images/cleaning-icon-3.png";
import formBackground from "./css/images/form-background.jpg";
import Menu from './components/Menu';
import fixNav from './FixNav';
import Axios from 'axios';


function Home() {

  useEffect(() => {
    fixNav();
    localStorage.setItem('page', 'home');
    document.querySelector('.menu').style.marginTop = "12vh";

  }, []);

  const [fullname, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [preference, setPreference] = useState("");

  const submitCallRequest = () => {
    Axios.post("http://localhost:3001/callrequest", {
      name: fullname,
      phone: phone,
      preference: preference,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        alert('We will call you at ' + preference + ' to discuss your cleaning needs.');
      }
    });
  }

  const handlePreferenceChange = (e) => {
    setPreference(e.value);
  }
  
  var times = [
    { value: '9:00am', label: '9:00am' },
    { value: '10:00am', label: '10:00am' },
    { value: '11:00am', label: '11:00am' },
    { value: '12:00pm', label: '12:00pm' },
    { value: '1:00pm', label: '1:00pm' },
    { value: '2:00pm', label: '2:00pm' },
    { value: '3:00pm', label: '3:00pm' },
    { value: '4:00pm', label: '4:00pm' },
    { value: '5:00pm', label: '5:00pm' },
    { value: '6:00pm', label: '6:00pm' },
];
  const styles = {
    menu: {
      marginTop: '12vh',
    },
  }
  
    return (
      <div>
        <div className='main'>
          <div className='slideshow'>
            <div className='slideshow-text'>
              <h1>Cleaning for Houston</h1>
              <Link to="/booknow" className='bookButton'>Book Now</Link>
            </div>
            
          </div>
          <div className='cleaning-services-perks'>
            <div className='perk'>
              <h2>Spotless Everytime.</h2>
              <img src="https://memorialmaids.nyc3.digitaloceanspaces.com/images/cleaning-icon-2.png" alt='Squeegee' />
              <p>With a Quality Control Specialist on duty, you can trust that your home will turn out spotless every time.</p>
            </div>
            <div className='perk'>
              <h2>The Clean-Promise.</h2>
              <img src="https://memorialmaids.nyc3.digitaloceanspaces.com/images/cleaning-icon-1.png" alt='Cleaning Rag Promise' />
              <p>Book your cleaning service online or over the phone. We'll work with your schedule to find a time that works for you.</p>
            </div>
            <div className='perk'>
              <h2>Family Owned.</h2>
              <img src="https://memorialmaids.nyc3.digitaloceanspaces.com/images/cleaning-icon-3.png" alt='Cleaning Rag Promise' />
              <p>Operations are handled directly with our family who all have the same goal in mind, providing an exceptional cleaning experience.</p>
            </div>
          </div>
          <div className='cleaning-email-request'>
            <h1>Let us call you.</h1>
            <div>
              <form>
                <p>Name: </p>
                <input type='text' placeholder='John Doe...' onChange={(e) => { setName(e.target.value); } }/>
                <p>Phone: </p>
                <input type='text' placeholder='281-713-4446...' onChange={(e) => { setPhone(e.target.value); } }/>
                <p>What time should we call you: </p>
                <Select 
                      options={times} 
                      onChange={ handlePreferenceChange }
                      value={times.value} 
                  />
                <br />
                <button onClick={submitCallRequest} >Submit</button>
              </form>
            </div>
          </div> 
            <div className='contact-info'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d442823.07184789487!2d-95.97179403437494!3d29.877097700000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c5f425cc9e03%3A0x6b8fcfea995a3c3!2sMemorial%20Maids!5e0!3m2!1sen!2sus!4v1671579978728!5m2!1sen!2sus"
            width={600} 
            height={450} 
            style={{border:0}} 
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade">

            </iframe>
              <div>
                <h1>Call or Text Us</h1>
                <h3>Phone</h3>
                <p>
                  <a href="tel:281-713-4446">281-713-4446</a>
                </p>
                <h3>Email</h3>
                <p>
                  <a href="mailto:contact@memorialmaids.com">contact@memorialmaids.com</a>
                </p>
               <br />

                
              </div>
            </div>

          

        </div>
      </div>
    );
  }
  
  export default Home;