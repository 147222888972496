import React from 'react';
import './css/terms.css';


function TermsOfService() {
    return (
        <div className='terms-of-service'>
            <h1>Terms of Service</h1>
            <p>This agreement for cleaning services between "Client" and MEMORIAL MAIDS will be effective on day of service and will remain active for recurring services. Services will be provided with accordance to these guidelines:</p>
            <div>
                <h4>1. SCOPE OF SERVICE</h4>
                <p>MEMORIAL MAIDS provides standard, deep, move in/out, and carpet cleaning to residence and is subject to entering their home. Here is what to expect from each service:
Basic Cleaning includes - Contractors will pick up small clutter, dusting, sweep floors, mop floors, vacuum floors, disinfect high traffic areas, standard stove cleaning, wipe down counters and other surfaces, change linens for bedrooms, standard tub and tile cleaning, polish chrome, scrub toilets, disinfect sinks, wipe sinks, and clean mirrors at a reachable distance with a 2-step ladder.
Deep Cleaning service includes - Dust: counters, backsplash, surfaces, baseboards, ceiling fans, blinds, air vents, light fixtures, picture frames, and Knick Knacks. Remove cobwebs. Empty and sanitize trash bins. Disinfect high-traffic areas. Clean front side of cabinets. Clean and wipe mirrors. Wipe and clean interior windows. Vacuum carpet and furniture. Disinfect counters. Scrub stove top and knobs. Clean face of appliances. Wipe dining table and chairs. Shine chrome. Clean inside microwave, refrigerator, and stove. Change linens. Remove grout from bathroom tile. Clean and disinfect shower/tub, tile, and counters. Clean shower door. Clean toilet, including the back and base. Clean vanity area and mirror.
Move In/Out Cleaning - Includes services from deep clean and carpet cleaning services. Carpet cleaning will vary depending on household.
*Residence must have maintained their home regularly. Homes that have not been maintained will be turned away and recommended to another service. Condition will be determined by a supervisor.</p>
            </div>
            <div>
                <h4>2. TERM</h4>
                <p>Services provided by MEMORIAL MAIDS will have an indefinite term until "Client" decides to terminate services.</p>
            </div>
            <div>
                <h4>3. PAYMENT</h4>
                <p>Payment is required to be sent to MEMORIAL MAIDS before/after services are completed. We accept cash, credit card, and debit cards. Checks are not accepted. Failure to pay will result to legal action. Late fee will be added for every week late, 5% of the bill for every week late. </p>
            </div>
            <div>
                <h4>4. PRODUCTS</h4>
                <p>MEMORIAL MAIDS will use its own products and supplies. Should "Client" require the use of special or hypoallergenic products, Client must notify MEMORIAL MAIDS before cleaning begins. Client may provide the product. If Client requires MEMORIAL MAIDS to use specialized products an additional fee may be assessed.</p>
            </div>
            <div>
                <h4>5. ACESS REQUIREMENT</h4>
                <p>"Client" will provide MEMORIAL MAIDS access to the Property, and to all areas of the Property scheduled to be cleaned as noted under Scope of Service, at the scheduled upon time. Failure to do so allows MEMORIAL MAIDS to treat the failure as a material breach and cancel the contract or to seek legal remedies.</p>
            </div>
            <div>
                <h4>6. CANCELLATION POLICY</h4>
                <p>In the event "Client" needs to cancel a scheduled cleaning appointment twenty-four (24) hour notice is required. Notice may be given via phone or email. Should "Client" fail to give twenty-four (24) hour notice on more than one occasion, Client must pay 30% of the fee for the canceled cleaning.
Canceling more than three (3) consecutive cleanings, or more than 7 total scheduled cleanings, without prior approval of MEMORIAL MAIDS, will be deemed a material breach and allow MEMORIAL MAIDS to cancel the contract or to seek legal remedies.
In the event MEMORIAL MAIDS needs to cancel a scheduled cleaning appointment twenty-four (24) hour notice will be given to Client. If MEMORIAL MAIDS fails to give twenty-four (24) hour notice, Client will receive one discounted cleaning.</p>
            </div>
            <div>
                <h4>7. TERMINATION OF CONTRACT</h4>
                <p>Either party may terminate this contract at any time by supplying a written notice of termination on a specified date to the other party, with at least thirty (30) days prior to the stated date of termination. Should "Client" terminate this contract prior to the expiration of the term, Client will forfeit any discount received. The difference between the regular price and the discounted price shall be due at the next scheduled cleaning.</p>
            </div>
            <div>
                <h4>8. APPLICABLE LAW AND VENUE</h4>
                <p>The terms of this agreement will be governed by the laws of the State of Texas. The state and federal courts located in Harris County, Houston will have exclusive jurisdiction over any case or controversy arising from or relating to this agreement, MEMORIAL MAIDS or any services provided by MEMORIAL MAIDS. Each Client consents irrevocably to personal jurisdiction in such courts with the respect to any matters and waives any defense of forum non conveniens.</p>
            </div>
            <div>
                <h4>9. ASSIGNEMENT</h4>
                <p>The rights and obligations created for Client under this agreement may not be assigned to any other party.</p>
            </div>
            <div>
                <h4>10. INDEMNIFICATION</h4>
                <p>Each party agrees to indemnify and hold harmless the other party and its employees, members, land-lord, successors, and assigns, from any claims, liabilities, losses, damages, and expenses asserted against the other party and arising out of the indemnifying party’s negligence, willful misconduct, and negligent performance of, or failure to perform, any of its duties or obligations under this Agreement. The provisions of this indemnification are solely for the benefit of the parties hereto and not intended to create or grant any rights, contractual or otherwise, to another person or entity.</p>
            </div>
            <div>
                <h4>11. FORCE MAJEURE</h4>
                <p>MEMORIAL MAIDS and any of its employees or agents shall not be deemed to be in breach of this agreement for any delay or failure in performance caused by reasons out of its reasonable control, including acts of God or a public enemy; natural calamities; failure of a third party to perform; changes in the laws or regulations; actions of any civil, military or regulatory authority; power outage or other disruptions of communication methods or any other cause which would be out of the reasonable control of MEMORIAL MAIDS.</p>
            </div>
            <div>
                <h4>12. SEVERANCE</h4>
                <p>In the event that one or more of the provisions of this agreement shall be found unenforceable, illegal or invalid, it shall not affect any other provisions of this agreement, and this agreement shall be construed as if the provision found to be unenforceable, illegal or invalid had never been contained in the agreement, or the unenforceable, illegal or invalid provision shall be construed, amended and/or reformed to be made enforceable, legal and valid.</p>
            </div>
            <div>
                <h4>13. WAIVER OF CONTRACTUAL RIGHT</h4>
                <p>The failure of either party to enforce any provision of this Contract shall not be construed as a waiver or limitation of that party's right to subsequently enforce and compel strict compliance with every provision of this Contract.</p>
            </div>
            <div>
                <h4>14. ENTIRE AGREEMENT</h4>
                <p>This Contract contains the entire agreement of the parties, and there are no other promises or conditions in any other agreement whether oral or written concerning the subject matter of this Contract. This Contract supersedes any prior written or oral agreements between the parties.</p>
            </div>
        </div>
    )
}

export default TermsOfService;