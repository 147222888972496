import React, { useEffect } from 'react'
import Menu from './components/Menu';
import "./css/about.css";
import aboutUsPhoto from './css/images/about-us-photo.png';
import  fixNav  from './FixNav';

function About() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        fixNav();
        localStorage.setItem('page', 'about');
      }, []);
          
    return (
        <div>
            <div className='main'>
                <div className='about'>
                    <div className='about-our-cleaning-service'>
                        <h1>About Memorial Maids</h1>
                        <p>David Ramirez started Memorial Maids during the COVID-19 pandemic, providing cleaning services for the Memorial Area, and now surrounding areas all over Houston. Memorial Maids is operated by David and his family members, which share the same goal, providing a seamless experience at a reasonable cost. We work together to ensure you get back your time from cleaning, so that you can do the things you love.</p>
                        <h3>What we offer</h3>
                        <p>Our services are catered to residential customers. By focusing only on homes, we can ensure exceptional result the way you like it! Our experts clean every room like it's their own, and with a Quality Control Specialist at every visit, you can count on your home coming out flawless every time.</p>
                        <h3>Why choose Memorial Maids</h3>
                        <p>We guarantee that you will be happy with our service or we will do it again! We keep a custom file on your home to ensure that any special needs are met. We also work on keeping you with the same expert you know and love. Delivering our full potential every visit.</p>
                    </div>
                    <div className='about-us-img'>
                        <img src="https://memorialmaids.nyc3.digitaloceanspaces.com/images/about-us-photo.png" alt='About Us' />

                    </div>    

                </div>
            
            </div>    
        </div>
    )
}

export default About;