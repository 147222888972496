import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function ForgotPassword() {

  const styles = {
    forgot: {
      color: "black",
      fontSize: "1em",
      marginTop: "150px",
      display: "flex",
      justifyContent: "center",


    },
  }

  return (
    <div style={styles.forgot}>
      <br />
      <form>
      <h1>Forgot Password?</h1>
        <label>
          <p>Email: </p>
          <input
            type="text"
            placeholder="Email"
          />
        </label>
        <br />
        <button type="button">Submit</button>
      </form>
    </div>
  );
}

export default ForgotPassword;