import React, {useEffect} from 'react'
import { Link } from "react-router-dom";
import "./css/services.css";
import cleanHome1 from './css/images/clean-home-1.jpg';
import Menu from './components/Menu';
import fixNav from './FixNav';

function Services() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        localStorage.setItem('page', 'services');
        fixNav();

      }, []);

      
    
    return (
        <div>
            <div className='main'>
                <div className='services'>
                    <div className='title-container-services'><h1>Our Cleaning Services</h1></div>
                      
                    <div className='standard-cleaning'>
                        <div className='cleaned-home-1'>
                        
                        </div>
                        <div className='standard-cleaning-checklist'>
                            <h1>Standard Cleaning</h1>
                            <p>Standard cleaning is for one-time or recurring customers. Our customer service team can help you find the best service plan catered to you! Standard cleaning includes:</p>
                                
                                <i class="bi bi-caret-right">Dust baseboards and ceiling fans</i>
                                <br />
                                <i class="bi bi-caret-right">Sweep and mop</i>
                                <br />
                                <i class="bi bi-caret-right">Vacuum carpet and floors</i>
                                <br />
                                <i class="bi bi-caret-right">Wipe counters, face of appliances, mirrors, and stovetop</i>
                                <br />
                                <i class="bi bi-caret-right">Scrub sinks and shower/tub areas</i>
                                <br />
                                <i class="bi bi-caret-right">Disinfect high traffic areas</i>
                                <br />
                                <i class="bi bi-caret-right">Make bed or change linens (if provided)</i>
                                <br />
                                <br />
                                <br />
                                <Link to='/book-now' className='bookButton'>Book Now</Link> 
                        </div>
                    </div>
                    <div className='deep-cleaning'>
                        <div className='deep-cleaning-checklist'>
                            <h1>Deep Cleaning</h1>
                            <p>Our deep cleaning focuses on the kitchen, bathrooms, and hard-to-reach areas. Deep cleaning includes:</p>
                                
                            <i class="bi bi-caret-right">Dust ceiling fans, air vents, lamps, light fixtures, and knick-knacks</i>
                            <br />
                            <i class="bi bi-caret-right">Sweep and mop</i>
                            <br />
                            <i class="bi bi-caret-right">Vacuum carpet and floors</i>
                            <br />
                            <i class="bi bi-caret-right">Wipe counters, outside cabinets, the face of appliances, baseboards, mirrors, and stovetop</i>
                            <br />
                            <i class="bi bi-caret-right">Deep clean inside refrigerator, microwave, and stove</i>
                            <br />
                            <i class="bi bi-caret-right">Scrub sinks and shower/tub areas</i>
                            <br />
                            <i class="bi bi-caret-right">Clean and sanitize trash bins</i>
                            <br />
                            <i class="bi bi-caret-right">Disinfect high traffic areas</i>
                            <br />
                            <i class="bi bi-caret-right">Make bed or change linens (if provided)</i>
                            <br />
                            <i class="bi bi-caret-right">Interior windows (additional charge)</i>
                            <br />
                            <br />
                            <br />
                            <Link to='/book-now' className='bookButton'>Book Now</Link>
                        </div>
                        <div className='cleaned-home-2'>
                        </div>
                    </div>
                    <div className='move-in-out-cleaning'>
                        <div className='cleaned-home-3'>
                        </div>
                        <div className='move-in-out-cleaning-checklist'>
                            <h1>Move In/Out Cleaning</h1>
                            <p>Standard cleaning is for one-time or recurring customers. Our customer service team can help you find the best service plan catered to you! Standard cleaning includes:</p>
                                <i class="bi bi-caret-right">Dust baseboards and ceiling fans</i>
                                <br />
                                <i class="bi bi-caret-right">Sweep and mop</i>
                                <br />
                                <i class="bi bi-caret-right">Vacuum carpet and floors</i>
                                <br />
                                <i class="bi bi-caret-right">Wipe baseboards, counters, outside/inside cabinets, the face of appliances, mirrors, and stovetop</i>
                                <br />
                                <i class="bi bi-caret-right">Deep clean inside refrigerator, microwave, and stove</i>
                                <br />
                                <i class="bi bi-caret-right">Scrub sinks and shower/tub areas</i>
                                <br />
                                <i class="bi bi-caret-right">Clean and sanitize trash bins</i>
                                <br />
                                <i class="bi bi-caret-right">Disinfect high traffic areas</i>
                                <br />
                                <i class="bi bi-caret-right">Carpet shampooed and dried (at an additional cost based on sq. ft.)</i>
                                <br />
                                <i class="bi bi-caret-right">Interior/Exterior windows (additional cost)</i>
                                <br />
                                <br />
                                <br />
                                <Link to='/contact'><button className='bookButton contact-us-button'>Contact Us</button></Link>
                        </div>
                    </div>
                    <div className='rental-home-cleaning'>
                        
                        <div className='rental-home-cleaning-checklist'>
                            <h1>Rental Home Cleaning</h1>
                            <p>Designed for rental home owners. A rental home cleaning prepares your home for the next guest with the following additional services included. Reach out to our customer service team for pricing based on sq. ft. and recurrence. </p>
                                <i class="bi bi-caret-right">Dust baseboards and ceiling fans</i>
                                <br />
                                <i class="bi bi-caret-right">Sweep and mop</i>
                                <br />
                                <i class="bi bi-caret-right">Vacuum carpet and floors</i>
                                <br />
                                <i class="bi bi-caret-right">Wipe down microwave, oven, refrigerator, and inside other small appliances </i>
                                <br />
                                <i class="bi bi-caret-right">Scrub sinks and shower/tub areas</i>
                                <br />
                                <i class="bi bi-caret-right">Disinfect high traffic areas</i>
                                <br />
                                <i class="bi bi-caret-right">Wash linens and towels</i>
                                <br />
                                <i class="bi bi-caret-right">Make bed and arrange towels</i>
                                <br />
                                <i class="bi bi-caret-right">Change lightbulbs, and refill supplies as needed</i>
                                <br />
                                <br />
                                <br />
                                <Link to='/contact'><button className=' bookButton contact-us-button'>Contact Us</button></Link>
                        </div>
                        <div className='cleaned-home-4'>
                        </div>
                    </div>
                    <div className='carpet-cleaning'>
                        <div className='cleaned-home-5'>
                        </div>
                        <div className='carpet-cleaning-checklist'>
                            <h1>Carpet Cleaning</h1>
                            <p>After shampooing the carpet, the drying process can take up to 4 hours to complete. The room must be clear of furniture or nearly empty. An additional charge may apply if movers are needed. </p>
                            <br />
                            <Link to='/contact' className='faq-button bookButton'>FAQs</Link>
                        </div>
                    </div>
                    <div className='organization-services'>
                        <div className='organization-description'>
                            <h1>Organizational Services</h1>
                            <p>Book us for your next project! Our professional cleaners can help with organizing your pantry, drawers, closets, etc. Depending on your needs, we come up with a action plan to declutter your home so wherever you look, it's clean and in order. </p>
                            <h3>Examples: </h3>
                            
                                <i class="bi bi-caret-right">Closets</i>
                                <br />
                                <i class="bi bi-caret-right">Pantry</i>
                                <br />
                                <i class="bi bi-caret-right">Office</i>
                                <br />
                                <i class="bi bi-caret-right">Cabinets/Drawers</i>
                                <br />
                                <i class="bi bi-caret-right">Kitchen & Refrigerator</i>
                                <br />
                                <i class="bi bi-caret-right">Storing away Decorations from the Holiday</i>
                                <br />
                                <i class="bi bi-caret-right">Garage</i>
                                <br />
                                <i class="bi bi-caret-right">Laundry Room</i>
                                <br />
                                <i class="bi bi-caret-right">Storage Room</i>

                            <br />
                            <br />
                            
                            <div className='space'></div>
                            <Link to="/booknow" className='bookButton'>Book Now</Link>
                            
                        </div>
                        <div className='organization-photo'></div>
                    </div>
                    <div className='commerical-services'></div>

                </div>
            </div>
        </div>    
    );
  }
  
  export default Services;
  
