import { Component } from "react";
import '../css/calendar.css';
import CalendarDays from "./calendar/CalendarDays";
import "bootstrap-icons/font/bootstrap-icons.css";
import Appointments from "./calendar/Appointments";




export default class Calendar extends Component {
    constructor(){

        super();
        this.weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        this.months = ['January', 'February', 'March', 'April', 'May', 'June', 
        'July', 'August', 'September', 'October', 'November', 'December'];

        this.state = {
            currentDay: new Date()
        }
        



        
    }

    changeCurrentDay = (day) => {
        this.setState({ currentDay: new Date(day.year, day.month, day.number)});
    }

    nextMonth = () => {
        this.setState({ currentDay: new Date(this.state.currentDay.setMonth(this.state.currentDay.getMonth() + 1))});
    }

    previousMonth = () => {
        this.setState({ currentDay: new Date(this.state.currentDay.setMonth(this.state.currentDay.getMonth() - 1))});
    }
    today = () => {
        this.setState({ currentDay: new Date()});
    }
   
    
        
    


    

    render() { 
        return(
            <div>
                <div className="calendar-heading">
                    <h2>{this.months[this.state.currentDay.getMonth()]} {this.state.currentDay.getFullYear()}</h2>
                </div>
                <div className="calendar-body">
                    <div className="tools">
                        <button 
                        className="previous"
                        
                        type="button"
                        onClick={this.previousMonth}>
                            <i class="bi bi-arrow-left"></i>
                        </button>
                        
                        <button
                        className="today"
                        type="button"
                        
                        onClick={this.today}>
                            Today
                        </button>
                    
                        <button
                        className="next"
                        
                        type="button"
                        onClick={this.nextMonth}
                        >
                            <i class="bi bi-arrow-right"></i>
                        </button>
                    </div>
                    <div className="table-header">
                        {
                            this.weekdays.map((weekday) => {
                                return <div className="weekday"><p>{weekday}</p></div>
                            })
                        }
                    </div>
                <CalendarDays day={this.state.currentDay} changeCurrentDay={this.changeCurrentDay} />
                <Appointments day={this.state.currentDay} />
            </div>
            </div>
        )
        }
    

}