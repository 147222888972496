import { useEffect, useState } from 'react';
import { PaymentForm, CreditCard, ApplePay, GooglePay } from 'react-square-web-payments-sdk';



export default function TakePayment(setAmount) {
    const amount = setAmount;


    return (
        <div>
       
        <PaymentForm
            sandbox={true}  // Set to true to use Square's sandbox environment
            applicationId = 'sandbox-sq0idb-JmcyoKnvBfRqDlNiA7q5BQ'
            cardTokenizeResponseReceived={async (card, buyerVerificationToken) => {
                const response = await fetch('/square/createPayment', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        },
                    body: JSON.stringify({
                            sourceId: card.card,
                        }),
                    });
                    console.log(await response.json());
            }
            }
            createPaymentRequest={() => ({
                countryCode: 'US',
                currencyCode: 'USD',
                lineItems: [
                    {
                        name: 'Test Item',
                        quantity: '1',
                        basePriceMoney: {
                            amount: amount,
                            currency: 'USD',
                        },
                    },
                ],
                taxLineItems: [
                    {
                        name: 'Sales Tax',
                        percentage: '8.25',
                    },
                ],
                requestBillingContact: true,
                requestShippingContact: false,
                total: {
                    amount: amount, 
                    label: 'Total',
                },
            
            })}
            createVerificationDetails={() => ({
                amount: amount,
                currencyCode: 'USD',
                intent: 'CHARGE',
                billingContact: {
                    addressLines: ['123 Main St', 'Apt 1'],
                    familyName: 'Smith',
                    givenName: 'John',
                    locality: 'San Francisco',
                    administrativeDistrict: 'CA',
                    postalCode: '94111',
                    country: 'US',
                    email: 'email@mail.com',
                    phone: '415-555-5555',
                },
            })}

            locationId={'LSWWCZMZNG2WP'}

            >
                <CreditCard />
            
            </PaymentForm>
           
        </div>
    )
}
