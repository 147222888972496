import {React, useEffect, useState} from "react";
import PersonalInfo from "./PersonalInfo";
import Address from "./Address";
import LoginInfo from "./LoginInfo";
import Estimate from "./Estimate";

import { Navigate, useNavigate } from 'react-router-dom';
import Axios from "axios";



const referenceId = '';
Axios.defaults.withCredentials = true;

  

function SignUp() {

    const [loginStatus, setLoginStatus] = useState("");
    const [page, setPage] = useState(0);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
        addressline1: "",   
        addressline2: "",
        city: "",
        zip: "",
        pets: false,
        squareFootage: "",
        bedrooms: "",
        bathrooms: "",
        floors: "",
        interiorWindows: false,
        exteriorWindows: false,
        fridge: false,
        oven: false,
        baseboards: false,
        patio: false,
        carpet: false,
        blinds: false,
        cabinets: false,
        laundry: false,
        service: "",
        occurance: "",
        date: "",
        time: "",
        notes: "",

    });
    const register = () => {
  
        const idempotencyKey = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      
            Axios.post("http://localhost:3001/register", {
              email: formData.email,
              password: formData.password,
              name: formData.firstName,
              lastName: formData.lastName,
              phone: formData.phone,
              addressline1: formData.addressline1,
              addressline2: formData.addressline2,
              city: formData.city,
              zip: formData.zip,
              idempotencyKey: idempotencyKey,
              referenceId: referenceId,
              pets: formData.pets,
              squareFootage: formData.squareFootage,
              bedrooms: formData.bedrooms,
              bathrooms: formData.bathrooms,
              floors: formData.floors,
              interiorWindows: formData.interiorWindows,
              exteriorWindows: formData.exteriorWindows,
              fridge: formData.fridge,
              oven: formData.oven,
              baseboards: formData.baseboards,
              patio: formData.patio,
              carpet: formData.carpet,
              occurance: formData.occurance,
              date: formData.date,
              time: formData.time,
              notes: formData.notes,
              visits: 0,
      
            }).then((response) => {
              if (response.data.message) {
                setLoginStatus(false);
              } else {
                setLoginStatus(true);
                localStorage.setItem("user", formData.email);
                localStorage.setItem("name", formData.firstName);
                localStorage.setItem("lastName", formData.lastName);
                localStorage.setItem("phone", formData.phone);
                localStorage.setItem("auth", true);
                localStorage.setItem("address", formData.addressline1);
                localStorage.setItem("address2", formData.addressline2);
                localStorage.setItem("city", formData.city);
                localStorage.setItem("zip", formData.zip);
                localStorage.setItem("referenceId", referenceId);
                localStorage.setItem("visits", 0);
      
      
      
                navigate("/Dashboard")
              }
            });
        }
    

    const FormTitles = [ "Contact Information", "Address", "Estimate", " Login" ];

    const pageDisplay = () => {
        if (page === 0) {
            return <PersonalInfo formData={formData} setFormData={setFormData}/>
        } else if (page === 1) {
            return <Address formData={formData} setFormData={setFormData} />
        } 
        else if (page === 2){
            return <Estimate formData={formData} setFormData={setFormData} />
        } else {
            return <LoginInfo formData={formData} setFormData={setFormData} />
        }
    }

    const makeSpace = () => {
        if (page === 2){
            return <div><hr /></div>;
        }
    }

    return (
        <div>
            {pageDisplay()}
            <button 
                disabled={page === 0}
                onClick={() => {
                    setPage((currPage) => currPage - 1);
            }}>Prev</button>
            {makeSpace()}
            <button
                onClick={() => {
                if (page === FormTitles.length - 1) {
                    
                    register();
                }
                if (page === 0) {

                    if (formData.firstName && formData.lastName && formData.phone){
                        setPage((currPage) => currPage + 1);
                    }

                } 
                if (page === 1) {

                    if (formData.addressline1 && formData.city && formData.zip) {
                        setPage((currPage) => currPage + 1);
                    }
                }
                if (page === 2) {
                    
                    setPage((currPage) => currPage + 1);
                    

                }     
              
                }}>{page === FormTitles.length - 1 ? "Submit" : "Next"}</button>

        </div>
    )
}

export default SignUp;