import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import fixNav from '../FixNav';

export default function Menu({burger, setBurger}) {

  const [temFix, setTemFix] = useState('');

  useEffect(() => {
    if(localStorage.getItem('page') === 'home') {
      setTemFix('12vh');
    } else {
      setTemFix('9vh');
    }
  }, []);


  const styles = {
    menu: {
      marginTop: temFix,
      display: 'none',
    },
  }
  const changeBurger = () => {
    setBurger(false);
    fixNav();
  }

    return (
        <div className="menu" style={styles.menu}>
          <Link to="/" onClick={changeBurger}>Home</Link>
          <Link to="/about" onClick={changeBurger}>About Us</Link>
          <Link to="/services" onClick={changeBurger}>Our Services</Link>
          <Link to="/faqs" onClick={changeBurger}>FAQs</Link>
          <Link to="/contact" onClick={changeBurger}>Contact Us</Link>
        </div>
    )
}
