import {useEffect, useState} from 'react'
import { Link, Navigate } from "react-router-dom";
import Axios from "axios";
import '../css/dashboard.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { Button } from 'react-bootstrap';
import { alignPropType } from 'react-bootstrap/esm/types';
import Popup from 'reactjs-popup';
import TakePayment from './square/TakePayment.js';
import Calendar from './Calendar.js';




function Dashboard() {

    // Customer Info

    const [user, setUser] = useState(localStorage.getItem("name"));
    const [loginStatus, setLoginStatus] = useState("");
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");

    // Invoice Info

    const [invoice, setInvoice] = useState("");
    const [invoiceDate, setInvoiceDate] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [amountDue, setAmountDue] = useState("");
    const [invoiceURL, setInvoiceURL] = useState("");

    // Rewards Info
    const [rewards, setRewards] = useState("");
    const [referral, setReferral] = useState("");
    const [untilReward, setUntilReward] = useState(7 - localStorage.getItem('visits'));
    const [reward1, setReward1] = useState("bi bi-award reward");
    const [reward2, setReward2] = useState("bi bi-award reward");
    const [reward3, setReward3] = useState("bi bi-award reward");
    const [reward4, setReward4] = useState("bi bi-award reward");
    const [reward5, setReward5] = useState("bi bi-award reward");
    const [reward6, setReward6] = useState("bi bi-award reward");
    const [reward7, setReward7] = useState("bi bi-award reward");
    const [invoiceStatus, setInvoiceStatus] = useState("");

    // Feedback Info
    const [feedback, setFeedback] = useState("");
    const [feedbackSent, setFeedbackSent] = useState(false);
    const [goodClick, setGoodClick] = useState("bi bi-hand-thumbs-up good");
    const [badClick, setBadClick] = useState("bi bi-hand-thumbs-down bad");

    // Chat Info
    const [chat, setChat] = useState("");
    const [rep, setRep] = useState("David");






    Axios.defaults.withCredentials = true;
    

    useEffect(() => {


        if (user) {
            setLoginStatus(user);
            

        } else {
            setLoginStatus(false);
        }

        getRecentInvoice();
        loadRewards();

        
    }, []);


    if (loginStatus === false) {
        return (
            <Navigate to="/BookNow" />
        )
    } 

    

    const logout = () => {
        Axios.get("http://localhost:3001/logout").then((response) => {
            setLoginStatus(false);
            localStorage.clear();
        });
    };

    const addDecimal = (amountDue) => {



    }

    const getRecentInvoice = () => {
        Axios.post("http://localhost:3001/invoice", {
            foreignID: localStorage.getItem("referenceId"),

        }).then((response) => {

        
            setInvoice(response.data.invoiceID);
            setInvoiceDate(response.data.invoiceDate);
            setDueDate(response.data.invoiceDueDate);
            setInvoiceStatus(response.data.invoiceStatus);
            setAmountDue(response.data.invoiceAmount);
            setInvoiceURL(response.data.invoiceUrl);
            
        });
    };

    const handleGoodFeedback = () => {

        if (goodClick === "bi bi-hand-thumbs-up-fill") {
            setGoodClick("bi bi-hand-thumbs-up good");
            setBadClick("bi bi-hand-thumbs-down bad");
        }
        else {

            setGoodClick("bi bi-hand-thumbs-up-fill");
            setBadClick("bi bi-hand-thumbs-down bad");
        }

    }

    const handleBadFeedback = () => {
       if (badClick === "bi bi-hand-thumbs-down-fill") {
            setGoodClick("bi bi-hand-thumbs-up good");
            setBadClick("bi bi-hand-thumbs-down bad");
       }
       else {
            setGoodClick("bi bi-hand-thumbs-up good");
            setBadClick("bi bi-hand-thumbs-down-fill");
       }
    }

    const handleFeedback = () => {

        Axios.post("http://localhost:3001/feedback", {
            feedback: feedback,
            name: localStorage.getItem("name"),

        });

        setFeedbackSent(true);
    };

    const feedbackForm = () => {
        if (goodClick === "bi bi-hand-thumbs-up-fill") {
            return (
                <form className='feedback-form'>
                    <h3>What did you like about our service?</h3>
                    <textarea className='feedback-text' value={feedback}  required onChange={(e)=> {
                        setFeedback(e.target.value);
                    }}></textarea>
                    <br />
                    <button className='feedback-submit' type='button' onClick={handleFeedback}>Submit</button>
                </form>
            )
        }
        else if (badClick === "bi bi-hand-thumbs-down-fill") {
            return (
                <form className='feedback-form'>
                    <h3>What did you not like about our service?</h3>
                    <textarea className='feedback-text' value={feedback} rows="10" required onChange={(e)=> {
                        setFeedback(e.target.value);
                    }}></textarea>
                    <br />
                    <button className='feedback-submit' type='button' onClick={handleFeedback}>Submit</button>
                </form>
            )
        }
        else {
            return (
                <div></div>
            )
        }
    }

    const displayFeedback = () => {

        if (feedbackSent === false) {
            return ( 
                <div className='feedback'>
                    <h3>Feedback</h3>
                    <p>How was your experience with Memorial Maids?</p>
                    <div className='feedback-buttons'>
                        <i className={goodClick} onClick={handleGoodFeedback}></i>
                        <i className={badClick} onClick={handleBadFeedback}></i>
                    </div>
                    {feedbackForm()}    
                </div>
            )
        } else {
            return (
                <div className='feedback'>
                    <h3>Feedback</h3>
                    <p>Thank you for your feedback!</p>
                </div>
            )
        }
    }


    const loadRewards = () => {
        if (localStorage.getItem("visits") === "1") {
            setReward1("bi bi-award-fill reward");
        }
        else if (localStorage.getItem("visits") === "2") {
            setReward1("bi bi-award-fill reward");
            setReward2("bi bi-award-fill reward");
        }
        else if (localStorage.getItem("visits") === "3") {
            setReward1("bi bi-award-fill reward");
            setReward2("bi bi-award-fill reward");
            setReward3("bi bi-award-fill reward");
        }
        else if (localStorage.getItem("visits") === "4") {
            setReward1("bi bi-award-fill reward");
            setReward2("bi bi-award-fill reward");
            setReward3("bi bi-award-fill reward");
            setReward4("bi bi-award-fill reward");

        }
        else if (localStorage.getItem("visits") === "5") {
            setReward1("bi bi-award-fill reward");
            setReward2("bi bi-award-fill reward");
            setReward3("bi bi-award-fill reward");
            setReward4("bi bi-award-fill reward");
            setReward5("bi bi-award-fill reward");
        }
        else if (localStorage.getItem("visits") === "6") {
            setReward1("bi bi-award-fill reward");
            setReward2("bi bi-award-fill reward");
            setReward3("bi bi-award-fill reward");
            setReward4("bi bi-award-fill reward");
            setReward5("bi bi-award-fill reward");
            setReward6("bi bi-award-fill reward");
        }
        else if (localStorage.getItem("visits") === "7") {
            setReward1("bi bi-award-fill reward");
            setReward2("bi bi-award-fill reward");
            setReward3("bi bi-award-fill reward");
            setReward4("bi bi-award-fill reward");
            setReward5("bi bi-award-fill reward");
            setReward6("bi bi-award-fill reward");
            setReward7("bi bi-award-fill reward");
        }

    }




    return (
    <div>
        <div className='dashboard'>
            <h1>Hi {loginStatus}</h1>
            <div className='first-row'>
                <div className='user-info'>
                    <div className='user-address'>
                        <h3>{localStorage.getItem("address")}</h3>
                        {localStorage.getItem("address2") ? <h2>Address 2: {localStorage.getItem("address2")}</h2> : null}
                        <h3>{localStorage.getItem("city")}, TX {localStorage.getItem("zip")}</h3>
                        <button className='edit-address'>Moving soon?</button>
                    </div>
                    <div className='add-ons'>
                        <h3>Add-Ons for your next visit </h3>
                        <div className='add-ons-row-1'>
                            
                            <button id='add-on'>Refrigerator & Freezer</button>
                            <button id='add-on'>Inside Oven</button>
                            <button id='add-on'>Interior Windows</button>
                        </div>
                        <div className='add-ons-row-2'>
                            <button id='add-on'>Baseboards</button>
                            <button id='add-on'>Blinds</button>
                            <button id='add-on'>Patio Sweep</button>
                        </div>
                    </div>    
                </div>
                <div className='user-calendar'>
                    <div className='calendar'>
                        <Calendar />
                    </div>  
                </div>    
            </div>
            <div className='second-row'>
                <div className='invoice'>
                    
                    <div className='invoice-row' onLoad={getRecentInvoice}>
                        <h3>Invoice #{ invoice }</h3>
                        <p>Service Date: { invoiceDate }</p>
                        <p>Due Date: { dueDate } </p>
                        <p>Amount Due: ${ amountDue} </p>
                    </div>
                    <div className='invoice-row'>
                       <Popup trigger={<button className='pay-now'>Pay Now</button>} modal nested>
                        {close => (
                            <div className='modal'>
                                <h3>Amount due: ${amountDue}</h3>
                                <TakePayment setAmount = {amountDue}/>
                                <button className='close' onClick={close}>
                                    &times;
                                </button>
                            </div>
                            )}
                       </Popup>
                        
                    </div>    
                    <div className='invoice-row'>
                        <div className='status'>
                            <h3>{invoiceStatus}</h3>
                        </div>
                    </div>
                </div>
                <div className='chat'>
                    <p>Chatting with {rep}</p>
                    <div className='chat-box'>


                    </div>    
                </div>  
            </div> 
            <div className='third-row'>
                <div className='rewards'>
                    <h3>Loyalty Rewards</h3>
                    <div className='rewards-row'> 
                        <i className={reward1}></i>
                        <i className={reward2}></i>
                        <i className={reward3}></i>
                        <i className={reward4}></i>
                        <i className={reward5}></i>
                        <i className={reward6}></i>
                        <i className={reward7}></i>
                    </div>
                    <p>Only {untilReward} more visits until your next reward!</p>    
                </div>
            </div>
            <div className='fourth-row'>
                <div className='refer-a-friend'>
                    <h3>Refer a Friend</h3>
                    <p>Refer a friend and get 10% off your next appointment!</p>
                    <input type='text' placeholder="Enter your friend's email address"/>
                    <button>Send</button>
                </div>
                {displayFeedback()}
            </div>    
            <Button variant="secondary" type="button" onClick={logout}>Log Out</Button>
            
        </div>        
    </div>
    );
    }

  
  export default Dashboard;
  
