import { BrowserRouter, Link } from 'react-router-dom';
import './App.css';
import BookNow from './BookNow';
import './css/footer.css'
import PrivacyPolicy from './PrivacyPolicy.js';
import TermsOfService from './TermsOfService.js';



function Footer() {

    const styles = {
        social: {
            color: 'blue',
        },
    }

  return (
    <BrowserRouter>
    <div className="footer">
        <div className='our-info'>
            <div className='our-info-section'>
                <h3>Mailing Address</h3>
                <p>PO Box 430871</p>
                <p>Houston, TX 77243</p>
            </div>
            <div className='our-info-section'>
                <h3>Hours</h3>
                <h4>Monday - Friday</h4>
                <p>8:00 AM - 6:00 PM</p>
                <h4>Saturday - Sunday</h4>
                <p>9:00 AM - 3:00 PM</p>
            </div>
            <div className='our-info-section'>
                <h3>Contact Us</h3>
                <p><a href="mailto:contact@memorialmaids.com">contact@memorialmaids.com</a></p>
                <p><a href='tel:2817134446'>281-713-4446</a></p>
            </div>
        </div>
        <div className='more-info'>
            <p>Follow us on <a target={"_blank"} style={styles.social} href='https://www.facebook.com/memorial-maids'>Facebook</a> and <a target={"_blank"} style={styles.social} href='https://www.yelp.com/biz/memorial-maids-houston?utm_campaign=www_business_share_popup&utm_medium=copy_link&utm_source=(direct)'>Yelp</a>.</p>
            <p>Now accepting Bitcoin, Ethereum, and Litecoin. </p>
            <p>Featured in Redfin's Blog: <a href="https://www.redfin.com/blog/how-to-keep-crickets-away-from-your-house/">Article</a></p>
            <p>Proudly serving Houston, Cypress, Memorial, Katy, Spring, and The Woodlands.</p>
            <p>Copyright © 2020 Memorial Maids - All Rights Reserved.     <Link to="/termsofservice">Terms of Service</Link>   -   <Link to="/privacypolicy">Privacy Policy﻿</Link></p>
        </div> 
        
    </div>
    
  
    </BrowserRouter>
  );
}

export default Footer;
