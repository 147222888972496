import {React, useEffect, useState} from "react";
import Menu from "./Menu";
import fixNav from '../FixNav';


export default function Test() {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        fixNav();
        localStorage.setItem('page', 'test');
    }, []);

    const styles = {
        scheduler: {
            
            marginTop: '12vh',
            

        },
        schedule: {
            padding: '5px',
            marginRight: 'auto',
            marginLeft: 'auto',
            display: 'flex',

        },
        
    }


    return (
        <div>
            <Menu />
            <div className='main'>
                <div className="temporary-scheduler" style={styles.scheduler}> 
                    <iframe src="https://app.squarespacescheduling.com/schedule.php?owner=21025918" title="Schedule Appointment" style={styles.schedule} width="100%" height="900" frameBorder="0"></iframe><script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
                </div>
            </div>
        </div>
    )
}