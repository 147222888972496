import React from 'react'
import './css/privacy.css'

function PrivacyPolicy() {
    return (
        <div className='privacy'>
            <h1>Privacy Policy</h1>
            <p>Welcome to Memorial Maids. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our cleaning services.</p>
            
            <h2>Information We Collect</h2>
            <p>We may collect personal information such as your name, contact details, and address when you schedule a cleaning service with us. We may also collect payment information to process transactions.</p>
            
            <h2>How We Use Your Information</h2>
            <p>We use the information you provide to schedule and provide cleaning services, process payments, and communicate with you about our services. We may also use your information for internal purposes such as improving our services.</p>
            
            <h2>Information Sharing</h2>
            <p>We do not sell, trade, or otherwise transfer your personal information to third parties. However, we may share your information with trusted service providers who assist us in operating our business.</p>
            
            <h2>Data Security</h2>
            <p>We implement various security measures to protect your personal information. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.</p>
            
            <h2>Changes to Privacy Policy</h2>
            <p>We reserve the right to update or change our Privacy Policy at any time. Any changes will be posted on this page.</p>
            
            <h2>Contact Us</h2>
            <p>If you have any questions or concerns about our Privacy Policy, please contact us at privacy@memorialmaids.com.</p>
            
            <p>This Privacy Policy was last updated on [Date].</p>
        </div>
    )
}

export default PrivacyPolicy;