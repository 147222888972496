import axios from "axios";
import { React, useState }from "react";

export default function Appointments(props) {

    let appointments = [];

    const [selected, selectedAppointment] = useState("");
    const [clicked, clickIt ] = useState(false);
    const [background, setBackground] = useState("rgb(37, 150, 190)");

    

    const ordinal = () => {
        if(props.day.getDate() === 1 || props.day.getDate() === 11 || props.day.getDate() === 21 || props.day.getDate() === 31){
            return (<>st</>)
        }
        if(props.day.getDate() === 2 || props.day.getDate() === 22) {
            return (<>nd</>)
        }
        if(props.day.getDate() === 3 || props.day.getDate() === 23){
            return <>rd</>
        }
        else {
            return (<>th</>)
        }
        
    }

    const checkAvailability = () => {
        axios.get("http://localhost:3001/checkAvailability", {
            day: props.day.getDate(),
            month: props.day.getMonth(),
            year: props.day.getFullYear(),
        }).then((response) => {
            if(response.data.message) {
                console.log(response.data.message);
            } else {
                appointments = response.data;
            }
        })
    }

    const checkAppointment = (time) => {
        if(appointments.includes(time)) {
            return true;
        } else {
            return false;
        }
    }



   

    const times = [
        "9:00 AM",
        "9:30 AM",
        "10:00 AM",
        "12:30PM",
        "1:00 PM",
        "3:30PM",
    ];

    function MouseOver(event) {
        event.target.style.color = "black";
        if(!clicked) {
            event.target.style.backgroundColor = "#abdbe3";

        }
    
    }
    
    function MouseOut(event) {
        event.target.style.color = "white";
        if (!clicked) {
            event.target.style.backgroundColor = "rgb(37, 150, 190)";
        }

    }

    function onClick(event) {
        event.target.style.backgroundColor = "red";
        clickIt(true);
    }
    function OnLoad(event) {
        
    }

        
    const styles = {

        availableAppointments: { 
 
            width: '120px',
            height: '50px',
            display: 'inline-grid',
            marginRight: 'auto',
            marginLeft: 'auto',
            backgroundColor: background,
            color: 'white',
            fontSize: '1.5rem',
            
              
        },
        
     
        slot: {
            display: 'grid',
            
            gridTemplateColumns: 'repeat(3, 2fr)',
            
            

        }

        
    }
  
    

    return (
        <div>
            <div>
                <h3>{props.day.getDate()}{ordinal()}</h3>
                <div className="appointment">
                    <h4>Available Times: </h4>
                    
                        <div style={styles.slot}>
                            {times.map((time, index) => (
                                <button style={styles.availableAppointments} type="button" key={index} onLoad={OnLoad} onClick={onClick} onMouseEnter={MouseOver} onMouseOut={MouseOut}>{time} </button>
                            ))}
                        </div>
                        <p>{selected}</p>
                    <p></p>
                </div>
            </div>
        </div>
    )
}